<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6"
                :sm="8"
                :xs="12">
          <el-form-item label="教师">
            <el-input placeholder="教师姓名或手机"
                      style="width:100%"
                      v-model="form.keyword" />
          </el-form-item>
        </el-col>
        <el-col :span="6"
                :sm="8"
                :xs="12">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6"
                :sm="8"
                :xs="12">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择截止时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6"
                :lg="6"
                :xl="6"
                :md="6"
                :sm="12"
                :xs="12">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- :summary-method="getSummaries" -->
    <!--  show-summary-->
    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight + 45"
              :data="tableData"
              style="width: 100%">
      <!-- 教师ID、手机、用户名、批改试卷总数、批改题目总数 -->
      <el-table-column prop="edu_user_id"
                       align="center"
                       label="教师ID" />
      <el-table-column prop="edu_user_mobile"
                       align="center"
                       label="教师手机" />
      <el-table-column prop="edu_user_name"
                       align="center"
                       label="教师姓名" />
      <el-table-column prop="effective_count"
                       align="center"
                       label="有效答疑次数" />
      <el-table-column prop="fail_count"
                       align="center"
                       label="未通过审核答疑次数" />
      <el-table-column prop="delete_count"
                       align="center"
                       label="被删除答疑次数" />
      <el-table-column prop="re_count"
                       align="center"
                       label="重复答疑次数" />
      <el-table-column prop="name"
                       align="center"
                       width="200"
                       label="操作">
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="详情"
                        placement="top-start">
              <img src="@/assets/icons/icon-查看@2x.png"
                   @click="showForm(row)"
                   alt="">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { frontDay, nowDateTime, now_month_time } from '@/utils/date.js'
export default {
  data () {
    return {
      tableData: [],
      options: [],
      form: {
        keyword: '',
        begin_time: now_month_time(),
        end_time: nowDateTime()
      },
      page: 1,
      size: 10,
      totalElements: 0,
      total: {
        effective_count: '',
        fail_count: '',
        delete_count: '',
      }

    }
  },
  mounted () {
  },
  methods: {
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/discuss',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.total = res.data.total
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      let form = {
        begin_time: this.form.begin_time,
        end_time: this.form.end_time,
        edu_user_id: row.edu_user_id
      }
      this.$router.push('/forum_statics/teacher_list?form=' + JSON.stringify(form))
    },
    exportExcel () {
      this.$http({
        url: '/api/v2/stats/discuss_export',
        method: 'get',
        responseType: "blob",
        params: this.form
      }).then(res => {
        console.log('res', res);
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `答疑统计${this.form.begin_time}${'-' + this.form.end_time}.xlsx`
        a.click()
      })
    },
    // // 统计行
    // getSummaries () {
    //   const sums = [];
    //   sums[0] = '教师合计'
    //   sums[1] = ''
    //   sums[2] = ''
    //   sums[3] = this.total.effective_count
    //   sums[4] = this.total.fail_count
    //   sums[5] = this.total.delete_count

    //   return sums;
    // },
  }
}
</script>

<style lang="scss" scoped>
</style>